import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .linktree-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        I’m currently looking for any new opportunities, my inbox is always open. Whether you have a
        question or just want to say hi, I’ll try my best to get back to you!
      </p>

      <br></br>
      <h2 className="title">Need resume pasword?</h2>

      <p>
        My resume is passoword protected, for some privacy reasons (cuz it has my personal phone
        number 😊), but you can get the password by sending me a message of concern for resume at my
        email or any of my social media.
      </p>

      <a className="linktree-link" href={`https://linktr.ee/asharbinkhalil`}>
        Contact me literally anywhere.
      </a>
    </StyledContactSection>
  );
};

export default Contact;
